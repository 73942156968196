import noop from '@anm/helpers/noop';
import { ChangeEvent, FC } from 'react';
import { CheckboxContainer, CheckboxImage, HiddenCheckbox, StyledCheckbox } from './styles';

export * from './styles';

export type CheckboxProps = {
  checked: boolean;
  className?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: FC<CheckboxProps> = ({ className = '', checked, onChange = noop, ...props }) => (
  <CheckboxContainer {...{ className }}>
    <HiddenCheckbox {...{ ...props, checked, onChange }} />
    <StyledCheckbox {...{ checked }}>
      <CheckboxImage src="images/icons/checkbox.svg" alt="checkbox" />
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
