import LanguageSwitcher from '@anm/components/LanguageSwitcher';
import LangScripts from '@anm/components/LanguageSwitcher/LangScripts';
import Termly from '@anm/components/Termly';
import ReviewSnippet, { getUrlPath } from '@anm/components/structures/Review';
import organizationSnippet, { isMainPage } from '@anm/components/structures/organization';
import { getFallBackUrl, getSrc } from '@anm/helpers/image';
import fbPixelHandlers from '@anm/helpers/loadFBPixel';
import sumoHandlers from '@anm/helpers/loadSumo';
import Head from 'next/head';
import React, { FC } from 'react';

import lfConfig from '../../config';
import isWebp from '../../helpers/images/isWebp';

const { loadSumo } = sumoHandlers;
const { loadFBPixel } = fbPixelHandlers;
const { langs } = lfConfig;

export type HeadProps = {
  path: string;
  title: string;
  preview: string;
  description: string;
  noindex?: boolean;
  canonical?: string;
  facebookPixelIds?: string[];
  ads?: boolean;
};

const noEndingSlash = (s?: string) => {
  if (s && s.endsWith('/')) return s.substring(0, s.length - 1);
  return s;
};

const MetaHead: FC<HeadProps> = ({
  path,
  title,
  preview,
  noindex,
  canonical,
  description,
  facebookPixelIds
  // ads
}) => {
  const previewUrl = isWebp(preview) ? getFallBackUrl(preview) : getSrc(preview);
  const _canonical = noEndingSlash(canonical || path);

  return (
    <>
      <LanguageSwitcher />
      <Head>
        <LangScripts {...langs} path={_canonical} />

        {/*{ads && <script></script>}*/}

        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-TPDLTPF"></script>

        <link rel="icon" type="image/x-icon" href={`${lfConfig.cdnUrl}static/wv-favicon.ico`} />

        <link rel="canonical" href={_canonical} />
        {noindex && <meta name="robots" content="noindex, nofollow" />}
        <meta charSet="utf-8" />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> TODO: remove if no needed, attempt to fix WEB-6602 */}

        <meta property="og:url" content={noEndingSlash(path)} />

        <title>{`${title} | Wave.video`}</title>
        <meta property="og:title" content={`${title} | Wave.video`} />
        <meta name="twitter:title" content={`${title} | Wave.video`} />

        <meta name="twitter:image" content={previewUrl} />
        <meta property="og:image" content={previewUrl} />

        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:type" content="website" />

        <meta name="google-site-verification" content="hk8J_7_ECOaQHTshxD-UJMmRxGoq5MTvJR72Q_Mf_Sw" />

        {isMainPage(path) && organizationSnippet()}

        {loadSumo(path)}
        {facebookPixelIds?.map(id => loadFBPixel(id))}
      </Head>
      <Termly />
      <ReviewSnippet pageName={getUrlPath(path)} />
    </>
  );
};

export default MetaHead;
