import { HOUR_MS, MINUTE_MS, MONTH_MS, YEAR_MS } from '@anm/constants/date';
import { Logger } from '@anm/helpers/Debugger';
import { Destination, DestinationKind } from 'types/stream';

export const FB_PRIVACY_PUBLIC = 'EVERYONE';
export const FB_PRIVACY_FRIENDS = 'ALL_FRIENDS';
export const FB_PRIVACY_PRIVATE = 'SELF';

export const YT_PRIVACY_PUBLIC = 'public';
export const YT_PRIVACY_UNLISTED = 'unlisted';
export const YT_PRIVACY_PRIVATE = 'private';

type DestinationCap = {
  network: Destination;

  titleMaxLength: number;
  descriptionMaxLength: number;

  color?: string;
  iconUrl: string;

  minScheduleInterval?: number;
  maxScheduleInterval?: number;
  maxTimeAfterScheduled?: number;

  canReceiveComments?: boolean;
  canPostComments?: boolean;
  tokenDoesNotExpire?: boolean;

  canCustomizeThumbnail?: boolean;
  canCustomizeVisibility?: boolean;
  canCustomizeCategories?: boolean;
  canCustomizeDescription?: boolean;
  canCustomizeFbInstruction?: boolean;
  canCustomizeStreamRotation?: boolean;
};

export const getMinScheduleIntervalMessage = (network: string, kind = '', time: string) =>
  `<b>${network} ${kind}</b> live events must be scheduled for at least ${time} from now. </br>`;

export const getMaxScheduleIntervalMessage = (network: string, kind = '', time: string) =>
  `<b>${network} ${kind}</b> live events cannot be scheduled later than ${time} from now. </br>`;

export const defaultCap: Omit<DestinationCap, 'network' | 'iconUrl'> = {
  color: 'transparent',
  minScheduleInterval: 0,
  canPostComments: true,
  canReceiveComments: true,
  maxScheduleInterval: Infinity,
  tokenDoesNotExpire: false,
  maxTimeAfterScheduled: Infinity,

  canCustomizeVisibility: false,
  canCustomizeDescription: true,
  canCustomizeThumbnail: true,
  canCustomizeStreamRotation: false,
  canCustomizeFbInstruction: false,
  canCustomizeCategories: false,

  titleMaxLength: 100,
  descriptionMaxLength: 5000
};

const FacebookProfileCaps: DestinationCap = {
  ...defaultCap,
  color: '#1877F2',
  iconUrl: 'images/icons/fb-ic.svg',
  maxScheduleInterval: YEAR_MS,
  minScheduleInterval: MINUTE_MS,
  network: 'FACEBOOK',
  canPostComments: false,
  canCustomizeVisibility: true
};
const FacebookGroupCaps: DestinationCap = {
  ...defaultCap,
  color: '#1877F2',
  iconUrl: 'images/icons/fb-ic.svg',
  minScheduleInterval: MINUTE_MS,
  maxScheduleInterval: YEAR_MS,
  network: 'FACEBOOK',
  canPostComments: false,
  canCustomizeFbInstruction: true
};
const FacebookPageCaps: DestinationCap = {
  ...defaultCap,
  color: '#1877F2',
  iconUrl: 'images/icons/fb-ic.svg',
  minScheduleInterval: MINUTE_MS,
  maxScheduleInterval: YEAR_MS,
  network: 'FACEBOOK'
};

const LinkedinProfileCaps: DestinationCap = {
  ...defaultCap,
  color: '#0A66C2',
  iconUrl: 'images/icons/linkedin-ic.svg',
  network: 'LINKEDIN',
  minScheduleInterval: 15 * MINUTE_MS,
  canPostComments: false,
  maxTimeAfterScheduled: HOUR_MS * 2,
  titleMaxLength: 70
};
const LinkedinPageCaps: DestinationCap = {
  ...defaultCap,
  color: '#0A66C2',
  iconUrl: 'images/icons/linkedin-ic.svg',
  network: 'LINKEDIN',
  minScheduleInterval: 15 * MINUTE_MS,
  canPostComments: false,
  titleMaxLength: 70
};

const YoutubeCaps: DestinationCap = {
  ...defaultCap,
  color: '#FF0000',
  iconUrl: 'images/icons/yt-ic.svg',
  network: 'YOUTUBE',
  minScheduleInterval: MINUTE_MS,
  canCustomizeVisibility: true,
  maxScheduleInterval: MONTH_MS * 2
};

const TwitchCaps: DestinationCap = {
  ...defaultCap,
  color: '#9146FF',
  iconUrl: 'images/icons/twitch-ic.svg',
  network: 'TWITCH',
  tokenDoesNotExpire: true,
  canCustomizeCategories: true
};

const InstagramCaps: DestinationCap = {
  ...defaultCap,
  color: '#fa0370',
  iconUrl: 'images/icons/insta-ic.svg',
  network: 'INSTAGRAM',
  tokenDoesNotExpire: true,
  canCustomizeVisibility: false,
  canCustomizeDescription: false,
  canCustomizeThumbnail: false,
  canCustomizeStreamRotation: true
};

const RTMPCaps: DestinationCap = {
  ...defaultCap,
  iconUrl: 'images/icons/rtmp-ic.svg',
  network: 'RTMP',
  tokenDoesNotExpire: true
};

const RTMPAmazonCaps: DestinationCap = {
  ...defaultCap,
  iconUrl: 'images/icons/amazon-ic.svg',
  network: 'RTMP',
  tokenDoesNotExpire: true
};

const WebinarCaps: DestinationCap = {
  ...defaultCap,
  iconUrl: 'images/icons/webinar-ic.svg',
  network: 'RTMP'
};

type Key =
  | 'YOUTUBE'
  | 'TWITCH'
  | 'RTMP'
  | 'WEBINAR'
  | 'FACEBOOK.group'
  | 'FACEBOOK.page'
  | 'FACEBOOK.profile'
  | 'LINKEDIN.page'
  | 'LINKEDIN.profile'
  | 'RTMP.AmazonLive'
  | 'INSTAGRAM';

export const allCaps: Record<Key, DestinationCap> = {
  'FACEBOOK.group': FacebookGroupCaps,
  'FACEBOOK.page': FacebookPageCaps,
  'FACEBOOK.profile': FacebookProfileCaps,
  'LINKEDIN.page': LinkedinPageCaps,
  'LINKEDIN.profile': LinkedinProfileCaps,
  YOUTUBE: YoutubeCaps,
  TWITCH: TwitchCaps,
  RTMP: RTMPCaps,
  'RTMP.AmazonLive': RTMPAmazonCaps,
  INSTAGRAM: InstagramCaps,
  WEBINAR: WebinarCaps,
};

const logger = new Logger('caps');

const getCaps = (network: Destination | 'WEBINAR', kind: DestinationKind | null = 'profile'): DestinationCap => {
  const kindKey = `${network}.${kind}` as Key;
  const key = kind && allCaps.hasOwnProperty(kindKey) ? `${network}.${kind}` : network;

  const caps = allCaps[key as Key];

  if (!caps) {
    logger.error(`could not find caps for network=${network}, kind=${kind}`);
  }

  return caps;
};

export default getCaps;
