import withModalControls, { WithModalControlsProps } from '@anm/HOCs/withModalControls';
import EscOnBlock from '@anm/components/KeyDownOnBlock';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import noop from '@anm/helpers/noop';
import useOutsideClick from '@anm/hooks/useOutsideClick';
import { useCallback, useEffect, useRef, FC } from 'react';

import Option from './Option';
import SelectOptionsContainer, { CustomOptionsContainer, OptionContainer } from './SelectOptionsContainer';
import SelectPlaceholder from './SelectPlaceholder';
import SelectValue from './SelectValue';
import SelectValueContainer from './SelectValueContainer';
import SelectWrapper from './Wrapper';
import useSelect from './hooks';

export {
  SelectValueContainer,
  Option,
  SelectOptionsContainer,
  SelectPlaceholder,
  SelectValue,
  SelectWrapper,
  useSelect,
  OptionContainer
};

export type SelectOption<T = any | undefined> = {
  label: string;
  value?: T;
  icon?: string;
  href?: string;
  isAutoClose?: boolean;
  renderIcon?(): JSX.Element;
  renderTooltip?(): JSX.Element;
};

export type SelectColors = 'gray' | 'blue';
export type SelectSizes = 'middle' | 'small' | 'narrow';
export type SelectVariants = 'with-border' | 'without-border' | 'with-2-columns';
export type AlignSelectOptions = 'left' | 'right';
type SelectActionOptions = 'hover' | 'click';

type HOCs = WithModalControlsProps;

export type RenderOptionProps = {
  option: SelectOption;
  currentOption?: SelectOption;
  onClick: () => void;
};
export type SelectProps = {
  name: string;
  color?: SelectColors;
  options: SelectOption[];
  size?: SelectSizes;
  label?: string;
  value?: SelectOption;
  variant?: SelectVariants;
  disabled?: boolean;
  openDelay?: number;
  className?: string;
  selectWidth?: number;
  multiSelect?: boolean;
  multiSelectValues?: string[];
  openByAction?: SelectActionOptions;
  alignSelectTo?: AlignSelectOptions;
  isClosableOnClick?: boolean;
  onClose?(): void;
  createLabel?: (label: string) => string;
  renderValue?(): JSX.Element;
  renderOption?(props: RenderOptionProps): JSX.Element;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  onChange?(currentOption: SelectOption): void;
  onActive?(isOpen: boolean): void;
  onOptionsHeightCalculated?(height: number): void;
};

let openTimeout: number;

const Select: FC<HOCs & SelectProps> = ({
  name,
  size,
  label,
  variant,
  options,
  disabled,
  openDelay = 0,
  color = 'gray',
  className,
  onClose,
  toggleModal,
  closeModal,
  selectWidth,
  isModalOpened,
  multiSelectValues,
  isClosableOnClick = true,
  openByAction = 'click',
  onChange = noop,
  multiSelect = false,
  value: currentOption,
  renderOption,
  onActive,
  onMouseEnter = noop,
  onMouseLeave = noop,
  createLabel = (label: string) => label,
  onOptionsHeightCalculated = noop,
  renderValue = () => (
    <SelectValue>
      {currentOption?.renderIcon?.()}
      {createLabel(currentOption && (currentOption.label || currentOption.value))}
    </SelectValue>
  )
}) => {
  const { selectRef, isOpened, setIsOpened } = useSelect();
  const isOpenByActionClick = openByAction === 'click';

  const optionsRef = useRef<HTMLDivElement>(null);

  const canOpenOnMobile = isOpened && isModalOpened;
  const canOpenOnDesktop = openByAction === 'hover' ? isOpened : isOpened && isModalOpened;
  const canOpen = (isMobileOrIPad() ? canOpenOnMobile : canOpenOnDesktop) && !!options.length;

  const toggleIsOpened = () => window.setTimeout(() => setIsOpened(!isOpened), 0);
  const closeSelect = () => {
    window.setTimeout(() => setIsOpened(false), 0);
    closeModal();
    onClose?.();
  };
  const handleClickValueContainer = () => {
    if (!isClosableOnClick) {
      setIsOpened(true);
      !isModalOpened && toggleModal();
    } else {
      toggleModal();
      toggleIsOpened();
    }
  };

  useOutsideClick(selectRef, (target: Element) => {
    if (!target.classList.contains('select')) {
      closeSelect();
    }
  });

  useEffect(() => {
    onActive?.(isOpened);
    const timer = setTimeout(() => {
      const input = selectRef.current?.querySelector('input');
      if (isOpened) {
        input?.focus();
        input?.select();
      }
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, [isOpened]);

  const handleMouseEnter = useCallback(() => {
    if (isOpenByActionClick) return;

    openTimeout = window.setTimeout(() => {
      setIsOpened(true);
      onMouseEnter();
    }, openDelay);
  }, [isOpenByActionClick]);

  useEffect(() => {
    if (!canOpen) return;

    const optionsHeight = optionsRef?.current?.clientHeight;

    onOptionsHeightCalculated(optionsHeight);
  }, [canOpen]);

  const handleMouseLeave = useCallback(() => {
    window.clearTimeout(openTimeout);
    !isOpenByActionClick && setIsOpened(false);
    onMouseLeave();
  }, [isOpenByActionClick]);

  const handleOptionClick = useCallback(
    (option: SelectOption) => {
      const canClose = option.isAutoClose === undefined ? true : option.isAutoClose;
      onChange(option);
      canClose && !multiSelect && closeSelect();
    },
    [options, multiSelect, onChange]
  );

  return (
    <SelectWrapper
      className={`${className} select`}
      ref={selectRef}
      onClick={() => setIsOpened(true)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...{
        ...(size && { size }),
        ...(disabled && { disabled })
      }}
    >
      <SelectValueContainer
        icon={currentOption?.icon || ''}
        color={color}
        onClick={handleClickValueContainer}
        {...{
          ...(size && { size }),
          ...(variant && { variant }),
          isOpened
        }}
      >
        {renderValue?.()}
        {!currentOption && <SelectPlaceholder>{label}</SelectPlaceholder>}
        <input name={name} type="hidden" value={currentOption && currentOption.value} />
      </SelectValueContainer>
      {canOpen && (
        <EscOnBlock onEsc={closeSelect}>
          <SelectOptionsContainer {...{ color, variant, selectWidth }} ref={optionsRef}>
            {options.map((option, index) =>
              renderOption ? (
                <CustomOptionsContainer key={index}>
                  {renderOption({
                    option,
                    currentOption,
                    onClick: () => handleOptionClick(option)
                  })}
                </CustomOptionsContainer>
              ) : (
                <Option
                  key={option.label}
                  color={color}
                  onClick={() => {
                    handleOptionClick(option);
                  }}
                  {...{
                    ...option,
                    multiSelect,
                    currentOption,
                    multiSelectValues
                  }}
                />
              )
            )}
          </SelectOptionsContainer>
        </EscOnBlock>
      )}
    </SelectWrapper>
  );
};

export default withModalControls(Select);
