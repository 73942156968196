import { useCallback, useState, ChangeEvent } from 'react';

const defaultParcel = (val: string) => val;

const useTextAreaOrInputState = (initialValue: string = '', valueParser = defaultParcel) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(valueParser(event.target.value));
  }, [valueParser]);

  const resetText = useCallback(() => {
    setValue('');
  }, []);

  const handleSetText = useCallback((val: string) => {
    setValue(valueParser(val));
  }, [valueParser]);

  return [value, handleChange, resetText, handleSetText] as const;
};

export default useTextAreaOrInputState;
