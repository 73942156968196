export default {
  'set 1': {
    list: [
      {
        title: 'Finally a Video App that Makes Video Creation Easy!',
        description:
          'I really enjoy the ability to easily create several canvas options for all the different social platforms. It also has a very robust stock image and stock video library to pull from.',
        author: 'Shannon L.',
        position: 'CEO, Marketing and Advertising',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Keeping it Simply Beautiful',
        description:
          'I have used Wave.video for a few months off and on as I was testing various software and platforms. But, the elegant simplicity and user friendly editor are what has made staying an easy choice. The assets and tools you have at your fingertips are outstanding. And, the developers work tirelessly to add new features continually. ',
        author: 'Robert R.',
        position: 'Small-Business',
        logo: {
          src: 'images/icons/g2crowd-logo.svg',
          link: 'https://www.g2.com/products/wave-video/reviews'
        }
      },
      {
        title: 'OMG! Could this be any easier',
        description:
          "I am officially in love with the software. I've already made 3 videos and it took me less thank 10 minutes. This is a gamechanger. I love that it can be branded, personalized and soooooo easy to use.",
        author: 'Yetunde S.',
        position: 'Coach, Public Relations and Communications',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      }
    ]
  },
  'set 2': {
    list: [
      {
        title: 'Great Tool for Social Media Videos',
        description:
          "I can easily scale my videos between social media platforms. It's easy to replace template images with my own.",
        author: 'Toiny W.',
        avatar: 'images/data/reviews/tony_w.jpg',
        position: 'Owner, Arts and Crafts',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'The only video creation tool you will need',
        description:
          'It takes 20-30 minutes to create a cool video for my clients. If you are not using it, give the free trial a try!',
        author: 'Anna F.',
        position: 'Blogger',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Easy to Use Software',
        description:
          'Wave is easy to use, has a lot of good Stock-Pictures and -Videos and Stickers. It´s easy to create a good looking video for social media.',
        author: 'Martin K.',
        position: 'Social Media Consultant',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      }
    ]
  },
  'set 3': {
    list: [
      {
        title: 'Best Value video marketing software',
        description:
          'Wave has enabled me to add video creation to my social media marketing efforts for my clients without significantly increasing my costs. They are and I are very happy. ',
        author: 'Andrew M.',
        position: 'Owner, Marketing and Advertising',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Easy to Use, Flexible with New Features',
        description:
          'Wave allows us to quickly produce high quality videos to promote our online courses and to prepare tutorials for learners and faculty members. The images, videos and music in the library are extensive and generally of high quality. ',
        author: 'Nicola D.',
        position: 'Marketing Coordinator, E-Learning',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'The right tool for the right job',
        description:
          'For specific jobs of shorter videos made for social media, this online video editing tool is a huge time saver and delivers excellent results. Love the stock audio, love how it remembers settings I previously set as a pre-set. Love the time saving.',
        author: 'User in Marketing and Advertising',
        position: 'Small-Business',
        logo: {
          src: 'images/icons/g2crowd-logo.svg',
          link: 'https://www.g2.com/products/wave-video/reviews'
        }
      }
    ]
  },
  'set 4': {
    list: [
      {
        title: 'Never run out of videos!',
        description:
          'It´s like a ninja package of Video Creation + Video Editing + Video Hosting' +
          ' + Live Streaming which is super cool!',
        author: 'Ashutosh A.',
        position: 'Founder/Owner',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Best All-in-One Live-streaming and Video-Production Software.',
        description:
          'You can do everything inside Wave.video: Video Production, Video Hosting, and' +
          ' Multistreaming. The team is implementing new things almost weekly based on customer reviews.',
        author: 'Olivier K.',
        position: 'Marketer',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Excellent software by an excellent team!',
        description:
          'I like how easy it is to use the live stream software and how easily I can repurpose the live' +
          ' streams for social media teasers with captions. It´s an all-in-one solution!',
        author: 'Lyle W.',
        position: 'Founder',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      }
    ]
  },
  'set 5': {
    list: [
      {
        title: 'Finally a Video App that Makes Video Creation Easy!',
        description:
          'Wave makes it possible for us to either create videos for them at a very' +
          ' affordable price or we train them on how to use the platform themselves.' +
          ' Their videos come out looking professional and they like that.',
        author: 'Shannon L.',
        position: 'CEO',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Best All-in-One Live-strThe Perfect cloud video editor.',
        description:
          'I love Wave.video because now I am no longer tied to my desktop and I can' +
          ' edit anywhere I want. Making videos with wave is a breeze and saves a lot' +
          ' of time.',
        author: 'Rolando M.',
        position: 'Creator',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Amazing Video Creator and Keeps Getting Better',
        description:
          'Using Wave is very easy yet with a lot of power. They have streamlined our' +
          ' process while allowing the creativity to be high. I run an agency that' +
          ' runs ads and social marketing for local businesses and Wave has been a key' +
          ' tool to allow us to deliver content that is engaging and delivers the' +
          ' businesses’ unique value.',
        author: 'Matt H.',
        position: 'Founder & Managing Director',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      }
    ]
  },
  'set 6': {
    list: [
      {
        title: 'Perfect for Video Creators',
        description:
          'Wave is an amazing text-to-video platform that I use almost every single day now. I can start from a blank canvas and go to a fully produced video in just a matter of minutes which is, honestly, quite wow.',
        author: 'Jyotbir L.',
        logo: {
          src: 'images/icons/g2crowd-logo.svg',
          link: 'https://www.g2.com/products/wave-video/reviews/wave-video-review-7728091'
        }
      },
      {
        title: 'Best Video Tool Hands Down',
        description: `Wave.video is the easiest video creation tool I've ever used. The editor is simple to use, and literally, everything you need is only a click or two away. Stock photos, videos, and music with pre-made templates make using Wave so amazingly easy.`,
        author: 'Robert R.',
        logo: {
          src: 'images/icons/g2crowd-logo.svg',
          link: 'https://www.g2.com/products/wave-video/reviews/wave-video-review-7709149 '
        }
      },
      {
        title: 'Easy to use and saves time',
        description: `I started using Wave for automatic captions, and I have quickly gotten into the realm of video editing. It is simple, quick, and easy to use.`,
        author: 'Leanne A',
        position: 'Company Manager',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/4796856/'
        }
      }
    ]
  },
  'set 7': {
    list: [
      {
        title: 'The easiest and most powerful tool to create videos',
        description:
          'It is super easy to use and has everything you need to create a professional video in minutes. It integrates very well with audio, image, and video banks. The editing options are very professional, and the templates are super beautiful.',
        author: 'Alexis L.',
        position: 'CEO',
        logo: {
          src: 'images/icons/g2crowd-logo.svg',
          link: 'https://www.g2.com/products/wave-video/reviews/wave-video-review-6905696'
        }
      },
      {
        title: 'Finally, a Video App that Makes Video Creation Easy!',
        description:
          'I really enjoy the ability to easily create several canvas options for all the different social platforms. It also has a very robust stock image and stock video library to pull from.',
        author: 'Shannon L.',
        position: 'CEO',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: ' https://www.capterra.com/p/172640/Wave-video/reviews/1695591/ '
        }
      },
      {
        title: 'Best Video Tool Hands Down',
        description: `Wave.video is the easiest video creation tool I've ever used. Stock photos, videos, and music with pre-made templates make using Wave so amazingly easy.`,
        author: 'Robert R.',
        logo: {
          src: 'images/icons/g2crowd-logo.svg',
          link: 'https://www.g2.com/products/wave-video/reviews/wave-video-review-7709149 '
        }
      }
    ]
  }
};
