import getFromCdn from '@anm/helpers/getFromCdn';
import { fromButtonToLink, media, mobileMenuItem } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { ButtonElementProps, ButtonSize, ButtonVariants } from '.';

export const whiteBgColorBorder = css`
  border-radius: 5px;
  border: solid;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  &:hover:not(:disabled) {
    background-color: #e9f8ff;
  }
`;

export const CloseButtonTabletStyles = media.tablet`
  background-size: 25px 25px;
  height: 25px;
  width: 25px;
  padding: 0;
  filter: drop-shadow( 0px 0px 3px #888);
`;

const heavyBorderButtonVerticalTextCentering = css`
  line-height: normal;
  &:after {
    display: inline-block;
    vertical-align: middle;
    height: 108%;
    content: '';
  }
`;

export const yellowButtonNormalState = css`
  background-color: #ffea00;
  border: 1px solid #ffea00;
  color: #292a2b;
  ${heavyBorderButtonVerticalTextCentering};
`;

const silverButtonCommonStyles = css`
  color: #292a2b;
  background-color: #f5f7fa;
  &:hover {
    background-color: #ebeef5;
  }
  &:disabled {
    color: #919eaa;
    pointer-events: none;
  }
`;

export const variants: { [key in ButtonVariants]: ReturnType<typeof css> } = {
  'dark-blue': css`
    color: #fff;
    background-color: var(--blue_200);
    &:hover {
      ${yellowButtonNormalState};
    }
  `,
  'light-blue': css`
    color: #fff;
    background-color: var(--blue_100);
    &:hover:not(:disabled) {
      background-color: var(--blue_200);
    }
    &:disabled {
      background-color: #ced1d8;
    }
  `,
  'soft-blue': css`
    color: var(--blue_100);
    background-color: transparent;
    &:hover {
      color: var(--blue_200);
      background-color: #ebeef5;
    }
  `,
  'blue-saturated': css`
    color: #fff;
    background-color: #30aef2;
    &:hover {
      background-color: #0693e0;
    }
    &:disabled {
      cursor: default;
      background-color: #ced1d8;
    }
  `,
  'light-blue-lowercase': css`
    color: #fff;
    background-color: var(--blue_100);
    &:hover,
    &:focus {
      background-color: var(--blue_200);
    }
    &:disabled {
      color: #919eaa;
      pointer-events: none;
      background: #f5f7fa;
    }
  `,
  black: css`
    color: #fff;
    background-color: var(--gray_900);
    &:hover {
      background-color: var(--gray_700);
    }
  `,
  'light-blue-lowercase-text-field': css`
    color: #fff;
    background-color: var(--blue_100);
    border-radius: 0 5px 5px 0;
    &:hover {
      background-color: var(--blue_200);
    }
    &:disabled {
      color: #919eaa;
      pointer-events: none;
      background: #f5f7fa;
    }
  `,
  'white-text-field': css`
    border: 1px solid var(--blue_100);
    color: var(--blue_100);
    background-color: #fff;
    border-radius: 0 5px 5px 0;
    &:hover {
      background-color: #e9f8ff;
    }
  `,
  white: css`
    border: 1px solid var(--blue_100);
    color: var(--blue_100);
    background-color: #fff;
    &:hover {
      background-color: #e9f8ff;
    }
  `,
  'light-blue-lowercase-separate': css`
    color: #fff;
    background-color: var(--blue_100);

    &:hover {
      background-color: var(--blue_200);
    }
  `,

  'border-transparent': css`
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    &:hover {
      background-color: rgba(175, 245, 254, 0.2);
    }
  `,
  red: css`
    ${heavyBorderButtonVerticalTextCentering};
    background-color: var(--error);
    border: 1px solid var(--error);
    color: #fff;
    &:hover {
      background-color: #ff4265;
    }
    &:disabled {
      opacity: 0.7;
      cursor: default;
    }
  `,
  'dark-red': css`
    ${heavyBorderButtonVerticalTextCentering};
    background-color: #b71a1a;
    border: 1px solid #b71a1a;
    color: #f3f6f7;
    &:hover {
      background-color: #c12828;
    }
    &:disabled {
      opacity: 0.7;
      cursor: default;
    }
  `,
  'bordered-red': css`
    ${heavyBorderButtonVerticalTextCentering};
    background-color: #fff;
    border: 2px solid var(--error);
    color: var(--error);
    &:hover {
      background-color: var(--error);
      color: #fff;
    }
  `,
  'bordered-light-red': css`
    background-color: #fff;
    border: 2px solid var(--error);
    color: var(--error);
    &:hover {
      background: rgb(255, 227, 227);
    }
  `,
  yellow: css`
    ${yellowButtonNormalState};
    font-size: 16px;
    &:hover {
      background-color: #ffc800;
      border: 1px solid #ffc800;
    }
  `,
  copy: css`
    background: url(${getFromCdn('images/icons/copy.svg')}) no-repeat 50% 50%;
  `,
  'arrow-left': css`
    border-radius: 5px 0 0 5px;
    background: url(${getFromCdn('images/arrow_left.svg')}) no-repeat 50% 50% rgba(224, 228, 237, 0.5);
    background-size: 12px 20px;
    &:hover {
      background-color: #e0e4ed;
    }
  `,
  'arrow-right': css`
    border-radius: 0 5px 5px 0;
    background: url(${getFromCdn('images/arrow_right.svg')}) no-repeat 50% 50% rgba(224, 228, 237, 0.5);
    background-size: 12px 20px;
    &:hover {
      background-color: #e0e4ed;
    }
  `,
  'square-gray': css`
    border-radius: 0;
    background-color: rgba(224, 228, 237, 0.5);
    color: #292a2b;
    &:hover {
      background-color: #e0e4ed;
    }
  `,
  'close-cross': css`
    background: url(${getFromCdn('images/close_cross.svg')}) no-repeat 50% 50% transparent;
    background-size: 38px;
    ${CloseButtonTabletStyles};
    filter: invert(31%) sepia(17%) saturate(513%) hue-rotate(165deg) brightness(110%) contrast(35%);
    &:hover {
      filter: none;
      ${CloseButtonTabletStyles};
    }
  `,
  'white-blue-border-share': css`
    ${heavyBorderButtonVerticalTextCentering};
    ${whiteBgColorBorder};
    border-color: var(--blue_100);
    color: var(--blue_100);
    border-width: 2px;
  `,
  'white-blue-border-hosting': css`
    ${heavyBorderButtonVerticalTextCentering};
    ${whiteBgColorBorder};
    border-color: var(--blue_100);
    color: var(--blue_100);
    border-width: 1px;
    &:disabled {
      color: #919eaa;
      border-color: #919eaa;
      cursor: default;
    }
  `,
  silver: css`
    ${silverButtonCommonStyles}
  `,
  simple: css`
    color: var(--blue_100);
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
    padding: 0;
  `,
  orange: css`
    color: #292a2b;
    background: #ffea00;

    &:hover {
      background: yellow;
    }
  `,
  'dark-border': css`
    color: #292a2b;
    border: solid 1px #292a2b;
    font-weight: bold;
    ${heavyBorderButtonVerticalTextCentering};
  `,
  'gray-border': css`
    color: #8b9499;
    border: 2px solid rgba(0, 56, 87, 0.2);
    border-radius: 5px;
    ${heavyBorderButtonVerticalTextCentering};
    &:disabled {
      background-color: transparent;
      cursor: default;
    }
  `,
  'silver-border': css`
    ${heavyBorderButtonVerticalTextCentering};
    ${silverButtonCommonStyles}
    border: 1px solid rgba(1, 83, 130, 0.2);
  `,
  'button-bordered-orange': css`
    border: 1px solid #fff;
    color: #fff;
    border-radius: 4px;
    background-color: transparent;
    ${heavyBorderButtonVerticalTextCentering};
  `,
  'simple-light-blue-underlined': css`
    color: #aff5fe;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-decoration: underline;
    padding: 0;
  `,
  'white-black-border-thin': css`
    ${heavyBorderButtonVerticalTextCentering};
    ${whiteBgColorBorder};
    border-color: #292a2b;
    color: #292a2b;
    border-width: 1px;
  `,
  'white-white-border-thin': css`
    ${heavyBorderButtonVerticalTextCentering};
    ${whiteBgColorBorder};
    border-color: #fff;
    color: #ffff;
    border-width: 1px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  `,
  link: css`
    ${fromButtonToLink};
    color: var(--blue_100);
    display: inline;
    font-size: 14px;
    line-height: 1;
    &:disabled {
      color: #919eaa;
      border-color: #919eaa;
      cursor: default;
    }
  `,
  'big-black-link': css`
    ${fromButtonToLink};
    color: var(--gray_900);
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    min-width: auto;
    height: initial;
    text-decoration: underline !important;
  `,
  'blue-link': css`
    ${fromButtonToLink};
    color: var(--blue_200);
  `,
  'link-with-dash': css`
    ${fromButtonToLink};
    position: relative;
    &:after {
      content: '|';
      position: absolute;
      right: -12px;
    }
    &:hover {
      &:after {
        color: #292a2b;
      }
    }
  `,
  'mobile-menu-item': css`
    ${mobileMenuItem};
    font-weight: normal;
  `,
  'white-blue-border-back': css`
    ${heavyBorderButtonVerticalTextCentering};
    border-radius: 5px;
    border: 2px solid var(--blue_200);
    background-color: transparent;
    color: var(--blue_200);
    font-size: 16px;
    font-weight: 700;
    &:hover {
      background-color: #eaf7fe;
    }
  `,
  'blue-heavy-border': css`
    ${heavyBorderButtonVerticalTextCentering};
    border-radius: 5px;
    border: 2px solid var(--blue_100);
    background-color: transparent;
    color: var(--blue_100);
    font-size: 16px;
    font-weight: 700;
    &:hover:not(:disabled) {
      background-color: rgba(32, 171, 210, 0.1);
    }
    &:disabled {
      color: #919eaa;
      border-color: #919eaa;
      cursor: default;
    }
  `,
  'white-bordered-share': css`
    ${heavyBorderButtonVerticalTextCentering};
    border-radius: 5px;
    color: #fff;
    border: 2px solid #ffffff;
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  `,
  gray: css`
    background-color: #ebeef5;
    color: #919eaa;
    font-weight: bold;
    &:hover {
      background-color: #ff4265;
      color: #fff;
    }
    &:disabled {
      color: #919eaa;
      cursor: default;
      &:hover {
        background-color: #ebeef5;
      }
    }
  `,
  'white-to-light-blue': css`
    background-color: ${({ theme }) => theme.whiteToLightBlueButtonBg};
    color: ${({ theme }) => theme.textColor};
    &:hover {
      background-color: ${({ theme }) => theme.whiteToLightBlueButtonHoverBg};
    }
  `,
  'no-wrapper-centered': css``,
  green: css`
    background: #4eda9d;
  `,
  'gray-system': css`
    background-color: #f4f6f7;
    color: #292a2b;
    border: 1px solid rgba(0, 56, 87, 0.2);
    &:hover {
      background-color: #e0e7eb;
    }
  `
};

const getHeightStyles = (height: number) => css`
  height: ${height}px;
  line-height: ${height}px;
`;

const sizes: { [key in ButtonSize]: ReturnType<typeof css> } = {
  icon: css`
    min-width: 25px;
    padding: 0;
  `,
  large: css`
    font-size: 16px;
    padding: 0 40px;
    ${getHeightStyles(50)};
  `,
  large_long: css`
    font-size: 16px;
    padding: 0 50px;
    ${getHeightStyles(50)};
  `,
  large_short: css`
    font-size: 16px;
    padding: 0 10px;
    min-width: 180px;
    ${getHeightStyles(50)};
  `,
  large_long_static: css`
    font-size: 16px;
    padding: 0 30px;
    min-width: 115px;
    ${getHeightStyles(50)};
  `,
  long_static_width: css`
    font-size: 16px;
    min-width: 220px;
    ${getHeightStyles(50)};
  `,
  small: css`
    padding: 0 10px;
  `,
  medium: css`
    font-size: 16px;
    padding: 0 25px;
    ${getHeightStyles(40)};
  `,
  'medium-wide': css`
    padding: 0 40px;
    min-width: 160px;
    border-radius: 8px;
    ${getHeightStyles(60)};
  `,
  big: css`
    ${getHeightStyles(50)};
    min-width: 168px;
    padding: 0 40px;
  `,
  'upper-big': css`
    ${getHeightStyles(60)};
    font-size: 20px;
    min-width: 196px;
    border-radius: 8px;
    text-align: center;
  `,
  'medium-short': css`
    font-size: 16px;
    padding: 0 25px;
  `,
  'upper-medium': css`
    font-size: 16px;
    padding: 0 20px;
    ${getHeightStyles(50)};
  `,
  medium_small: css`
    font-size: 16px;
    padding: 0 15px;
    ${getHeightStyles(40)};
  `,
  'full-width': css`
    font-size: 16px;
    padding: 0 25px;
    width: 100%;
    ${getHeightStyles(50)};
  `,
  'mobile-item': css`
    padding: 0;
    height: 69px;
    line-height: 69px;
  `,
  'link-medium': css`
    font-size: 14px;
  `,
  'medium-no-paddings': css`
    color: inherit;
    font-size: 14px;
    line-height: 18px;
    margin: 24px 0 0 0;
    letter-spacing: 0.01em;
  `,
  'small-simple-icon': css`
    width: 36px;
    height: 36px;
  `
};

const Button = styled.button<ButtonElementProps>`
  display: inline-block;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  vertical-align: top;
  white-space: nowrap;
  transition: color, border-color, background-color 0.3s ease;
  text-align: center;
  &:focus {
    outline: 0 none;
  }
  * {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
  }

  ${({ size, variant }) => [...sizes[size], variants[variant]]};
  ${({ textColor }) => textColor && `color: ${textColor}`};
`;

export default Button;
