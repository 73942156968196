import noop from '@anm/helpers/noop';
import CardModal from '@anm/components/modals/CardModal';
import { FC, useEffect } from 'react';
import isValidWebsiteUrl from '@anm/helpers/is/isValidWebsiteUrl';

import ModalSettings from '../components/ModalSettings';
import { GenerateVideoModalProps } from '../types';
import { Content, TitleInput, MainTitle, WrappedButtonLoader, Wrapper } from '../Wrapper';

import { useCreateVideoFromUrlHandlers } from './hooks';

const GenerateVideoFromUrlModal: FC<GenerateVideoModalProps> = ({
  timeList,
  isPending,
  clearJobId,
  onModalClose,
  onCreateVideo
}) => {
  useEffect(() => {
    clearJobId();
  }, []);

  const [
    text,
    activeTextSceneButton,
    activeStockAssetsButton,
    setTime,
    setMood,
    handleTextChange,
    handleCreateVideo,
    handleTextSceneChange,
    setActiveStockAssetsButton
  ] = useCreateVideoFromUrlHandlers(onCreateVideo);

  const isDisabledButton = !isValidWebsiteUrl(text);

  return (
    <CardModal
      {...{ onModalClose }}
      cardSettings={{
        isPadding: false,
        borderRadius: 10,
        variant: 'transparent'
      }}
    >
      <Wrapper>
        <Content>
          <MainTitle
            level={2}
            title="Convert a blog post into video"
            description="Insert a link to your article and get an AI-generated video <br/>for further editing and customization."
          />
          <TitleInput
            label="Link"
            inputVariant="simple"
            labelVariant="static-above"
            input={{
              name: 'url',
              value: text,
              onChange: handleTextChange,
              onFocus: noop,
              onBlur: noop
            }}
            helperTextVariant="static"
            meta={{} as any}
            placeholder="Past your link here"
          />

          <ModalSettings
            {...{
              setMood,
              setTime,
              timeList,
              activeTextSceneButton,
              handleTextSceneChange,
              activeStockAssetsButton,
              setActiveStockAssetsButton
            }}
          />

          <WrappedButtonLoader
            size="large"
            {...{ isPending }}
            onClick={handleCreateVideo}
            disabled={isDisabledButton}
            variant="light-blue-lowercase"
          >
            Next
          </WrappedButtonLoader>
        </Content>
      </Wrapper>
    </CardModal>
  );
};

export default GenerateVideoFromUrlModal;
