import { VideoResolution } from '@anm/data/media/videoConstrains';
import { Logger } from '@anm/helpers/Debugger';
import EventEmitter from '@anm/helpers/events/EventEmitter';
import isServer from '@anm/helpers/is/isServer';
import localStorage from '@anm/helpers/localStorage';
import _ from 'lodash/fp';
import isEmpty from 'lodash/fp/isEmpty';

import getMediaDevices from '../getMediaDevices';

const logger = new Logger('MediaManager');

export type VideoOptions = {
  resolution: VideoResolution;
  enable: boolean;
  mirror: boolean;
  maxQuality: boolean;
};

export type AudioOptions = {
  enable: boolean;
  stereo: boolean;
  echoCancellation: boolean;
  noiseSuppression?: boolean;
};

export type SpeakerOptions = { enable: boolean };

export type MediaOptions = VideoOptions | AudioOptions | SpeakerOptions;

export type MediaDeviceChangeEvent = {
  oldDevice?: MediaDeviceInfo;
  newDevice?: MediaDeviceInfo;
  external: boolean;
};

type Prefs = {
  deviceId: string;
  default: boolean;
  options: MediaOptions;
};

const deviceRE = new RegExp(/(.*) \(.*:.*\)$/);

export class MyInfo implements MediaDeviceInfo {
  private info: MediaDeviceInfo;
  readonly label: string;

  isDefault: boolean = false;

  constructor(info: MediaDeviceInfo) {
    this.info = info;

    const re = deviceRE.exec(info.label);
    this.label = (re && re[1]) || info.label;
  }

  get deviceId() {
    return this.info.deviceId;
  }

  get groupId() {
    return this.info.groupId;
  }

  get kind() {
    return this.info.kind;
  }

  toJSON(): any {
    return this.info.toJSON();
  }
}

export function deviceHash(info: MediaDeviceInfo | undefined) {
  return info && `${info.deviceId}/${info.label}`;
}

export function equal(i1: MediaDeviceInfo, i2: MediaDeviceInfo) {
  return i1.label === i2.label && i1.deviceId === i2.deviceId && i1.kind === i2.kind && i1.groupId === i2.groupId;
}

export class MediaDevicesList<Opt extends MediaOptions> {
  // Map of MediaDeviceInfos indexed by deviceId.
  private map = new Map<string, MyInfo>();

  readonly kind: MediaDeviceKind;
  readonly changeOptionsEvent = new EventEmitter<Opt>();
  readonly changeDeviceEvent = new EventEmitter<MediaDeviceChangeEvent>();

  private currentDevice?: MyInfo;
  private currentOptions: Opt;

  constructor(kind: MediaDeviceKind, options: Opt) {
    this.kind = kind;
    this.currentOptions = options;
  }

  private cachedInfos: MediaDeviceInfo[] = [];

  devices(): MediaDeviceInfo[] {
    const infos = Array.from(this.map.values());
    const prev = this.cachedInfos;
    let changed = false;
    if (infos.length === prev.length) {
      for (let i = 0; i < prev.length; i++) {
        if (!equal(prev[i], infos[i])) {
          changed = true;
          break;
        }
      }
    } else {
      changed = true;
    }

    if (changed) {
      this.cachedInfos = infos;
    }

    return this.cachedInfos;
  }

  get(deviceId: string) {
    return this.map.get(deviceId);
  }

  has(deviceId: string) {
    return this.map.has(deviceId);
  }

  getCurrentDevice() {
    return this.currentDevice;
  }

  setCurrentDevice(deviceId: string, external?: boolean) {
    const newDevice = this.get(deviceId);
    const oldDevice = this.currentDevice;
    if (deviceHash(newDevice) !== deviceHash(oldDevice)) {
      this.currentDevice = newDevice;
      this.saveToPrefs();
      this.changeDeviceEvent.emit({ oldDevice, newDevice, external: !!external });
    } else if (newDevice?.isDefault !== oldDevice?.isDefault) {
      this.currentDevice = newDevice;
      this.saveToPrefs();
    }
  }

  getCurrentOptions() {
    return this.currentOptions;
  }

  private serialize() {
    return Array.from(this.map.values())
      .map(info => deviceHash(info))
      .join(',');
  }

  refresh(_devices: MediaDeviceInfo[], external: boolean) {
    const oldValues = this.serialize();

    this.map = _devices
      .filter(device => device.kind === this.kind)
      .reduce((acc, device) => {
        acc.set(device.deviceId, new MyInfo(device));
        return acc;
      }, new Map());

    let defaultDevice: MyInfo | undefined = undefined;

    if (this.map.has('default')) {
      const def = this.map.get('default');
      for (const e of this.map) {
        const key = e[0];
        const val = e[1];
        if (key === 'default') continue;
        if (def!.label.endsWith(val.label)) {
          val.isDefault = true;
          this.map.delete('default');
          defaultDevice = val;
          break;
        }
      }
    }

    const devices = Array.from(this.map.values());

    const prefs = this.getFromPrefs();

    // this.setOptions(prefs.options as Opt);

    if (prefs.default && !!defaultDevice) {
      this.setCurrentDevice(defaultDevice.deviceId, external);
    } else {
      const deviceId = prefs.deviceId;
      if (this.has(deviceId)) {
        this.setCurrentDevice(deviceId, external);
      } else if (devices.length > 0) {
        this.setCurrentDevice(
          defaultDevice?.deviceId ||
            (devices.find(d => d.label && d.label.toLowerCase().includes('default')) || devices[0]).deviceId,
          external
        );
      } else {
        this.setCurrentDevice('', external);
      }
    }

    const newValues = this.serialize();

    return oldValues !== newValues;
  }

  getDefaultDevice() {
    const devices = Array.from(this.map.values());
    return devices.find(d => d.label && d.label.toLowerCase().includes('default')) || devices[0];
  }

  getDefaultDeviceId() {
    return this.getDefaultDevice()?.deviceId;
  }

  private nextDevicePosition = 0;

  getNextDevice(currentDeviceId = this.currentDevice?.deviceId) {
    const devices = Array.from(this.map.values()).filter(d => d.deviceId !== currentDeviceId);
    this.nextDevicePosition = this.nextDevicePosition >= devices.length - 1 ? 0 : this.nextDevicePosition + 1;

    return devices[this.nextDevicePosition] || this.getDefaultDevice();
  }

  getNextDeviceId() {
    return this.getNextDevice()?.deviceId;
  }

  private getFromPrefs(): Prefs {
    let p: Prefs;
    try {
      const prefs = JSON.parse(localStorage().getItem('device_' + this.kind) || '{}');
      p = {
        deviceId: prefs.deviceId ? prefs.deviceId : '',
        default: prefs.default ? prefs.default : false,
        options: typeof prefs.options === 'object' && !isEmpty(prefs.options) ? prefs.options : this.currentOptions
      };
    } catch (e) {
      p = {
        deviceId: '',
        default: true,
        options: this.currentOptions
      };
    }

    this.currentOptions = p.options as Opt;

    return p;
  }

  private saveToPrefs() {
    const prefs: Prefs = {
      deviceId: (this.currentDevice && this.currentDevice.deviceId) || '',
      default: (this.currentDevice && this.currentDevice.isDefault) || false,
      options: this.currentOptions
    };
    localStorage().setItem('device_' + this.kind, JSON.stringify(prefs));
  }

  dump() {
    logger.info(`media-list('${this.kind}'):`);
    for (const d of this.map) {
      const id = d[0];
      const device = d[1];

      logger.info(`  device[${id}] - ${device.deviceId} - '${device.label}'`);
    }
    logger.info(`  currentDevice: ${JSON.stringify(this.getCurrentDevice())}`);
    logger.info(`  currentOptions: ${JSON.stringify(this.getCurrentOptions())}`);
    logger.info(`  prefs: ${JSON.stringify(this.getFromPrefs())}`);
  }

  private setOptions(options: Opt) {
    if (!_.isEqual(this.currentOptions, options)) {
      this.currentOptions = options;
      this.changeOptionsEvent.emit(this.currentOptions);
      this.saveToPrefs();
    }
  }

  updateOptions(options: Partial<MediaOptions> = {}) {
    this.setOptions({ ...this.currentOptions, ...options });
  }

  enable() {
    this.updateOptions({ enable: true });
  }

  disable() {
    this.updateOptions({ enable: false });
  }
}

export type MediaChangeEvent = {
  webcamsChanged: boolean;
  micsChanged: boolean;
  speakersChanged: boolean;
};

export class MediaManager {
  readonly webcams = new MediaDevicesList<VideoOptions>('videoinput', {
    resolution: 'hd',
    enable: true,
    mirror: false,
    maxQuality: false
  });
  readonly mics = new MediaDevicesList<AudioOptions>('audioinput', {
    enable: true,
    stereo: false,
    echoCancellation: true
  });
  readonly speakers = new MediaDevicesList<SpeakerOptions>('audiooutput', {
    enable: true
  });

  // events emited when list of devices change
  readonly changeEvent = new EventEmitter<MediaChangeEvent>();

  devicesSnapshot: string = '';

  // mac/chrome
  //   default speaker/mic has id `default`, so we cannot use it as unique id.
  //   when changing speaker/mic new mic has id `default`, but different label
  // mac/safari
  //   there is no audio device with id `default` and there is no way to know which device is default

  constructor() {}

  async init() {
    if (isServer()) return;

    await this.refresh(false);

    navigator.mediaDevices.addEventListener('devicechange', async _ => {
      await this.refresh(true);
    });

    this.webcams.changeDeviceEvent.on(e => {
      logger.info('changed webcam to ' + e.newDevice?.label);
    });
    this.mics.changeDeviceEvent.on(e => {
      logger.info('changed mic to ' + e.newDevice?.label);
    });
    this.speakers.changeDeviceEvent.on(e => {
      logger.info('changed speaker to ' + e.newDevice?.label);
    });
  }

  sanitize(info: MediaDeviceInfo): MediaDeviceInfo {
    return new MyInfo(info);
  }

  async refresh(external: boolean) {
    const devices = await getMediaDevices();

    this.devicesSnapshot = devices.map(d => `[${d.kind}], id=${d.deviceId}, label='${d.label}'`).join('\n');
    const webcamsChanged = this.webcams.refresh(devices, external);
    const micsChanged = this.mics.refresh(devices, external);
    const speakersChanged = this.speakers.refresh(devices, external);

    if (webcamsChanged) this.webcams.dump();
    if (micsChanged) this.mics.dump();
    if (speakersChanged) this.speakers.dump();

    if (webcamsChanged || micsChanged || speakersChanged) {
      this.changeEvent.emit({
        webcamsChanged,
        micsChanged,
        speakersChanged
      });
    }
  }
}

let manager: MediaManager;
const mediaManager = () => {
  if (!manager) {
    manager = new MediaManager();
    manager.init();
  }

  return manager;
};

export default mediaManager;
