import BetaLabel from '@anm/components/BetaLabel';
import isMobile from '@anm/helpers/is/isMobile';
import useHover from '@anm/hooks/useHover';
import { useCallback, FC, MouseEvent } from 'react';

import { Action } from '..';

import ActionItemWrapper, { ActionItemLink, Crown } from './Wrapper';

export type ActionItemProps = Action & {
  isCrown?: boolean;
  hasIcon?: boolean;
  showBeta?: boolean;
  className?: string;
  autoClose?: boolean;
  isBigIcon?: boolean;
  hasTooltip?: boolean;
  showDivider?: boolean;
  onClick?: (e: MouseEvent) => void;
  closeDropdown?: () => void;
};

const AbsoluteLink: FC<ActionItemProps> = ({ name, target = '_self', ...other }) => (
  <ActionItemLink data-button={`dropdown action ${name}`} {...{ target, ...other }}>
    {name}
  </ActionItemLink>
);

const LocalLink: FC<ActionItemProps> = ({ name, renderLink = v => v, ...other }) => (
  <>
    {renderLink(
      <ActionItemLink as="span" {...other}>
        {name}
      </ActionItemLink>
    )}
  </>
);

export const Item: FC<ActionItemProps> = ({ href, showBeta, handler, isCrown, ...otherProps }) => {
  const isAbsoluteLink = href || handler;
  return (
    <>
      {isAbsoluteLink ? <AbsoluteLink {...{ href, handler, ...otherProps }} /> : <LocalLink {...otherProps} />}
      {isCrown && <Crown />}
      {showBeta && <BetaLabel positionVariant="top-right" />}
    </>
  );
};

const ActionItem: FC<ActionItemProps> = ({
  icon,
  disabled,
  iconHover,
  desktopOnly,
  handler,
  renderComponent,
  autoClose,
  closeDropdown,
  isBigIcon = false,
  showDivider = false,
  hasTooltip = false,
  ...otherProps
}) => {
  const [hoverRef, isHovered] = useHover<HTMLLIElement>();

  const currentIcon = iconHover && !isMobile() ? (isHovered ? iconHover : icon) : icon;

  const handleItemClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    const canClose = autoClose && !disabled;

    !disabled && handler?.();
    canClose && closeDropdown?.();
  }, [disabled, autoClose, handler]);

  if (renderComponent) {
    return (
      <ActionItemWrapper
        ref={hoverRef}
        icon={currentIcon}
        {...{ isBigIcon, disabled, showDivider }}
        isDesktopOnly={desktopOnly}
        onClick={handleItemClick}
      >
        {renderComponent()}
      </ActionItemWrapper>
    );
  }

  return (
    <ActionItemWrapper
      ref={hoverRef}
      icon={currentIcon}
      {...{ disabled, isBigIcon, showDivider }}
      isDesktopOnly={desktopOnly}
      onClick={handleItemClick}
    >
      <Item {...{ disabled, ...otherProps }} hasIcon={!!icon} onClick={handleItemClick} />
    </ActionItemWrapper>
  );
};

export default ActionItem;
