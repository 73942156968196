import { isProd } from '@anm/shared/mode';
import { User } from 'types/user';

const pauseRemoteConsumers = false;

const audioRecordingEnabled = false; // disable because EFS is disabled

const hot_rejoin = !isProd;

const isTwoOptionsPopupEnabled = true;

// show chooser how to record: via studio or locally
const videoRecorderChooser = true;

const qrcodes = true;

// @ts-ignore
const canUseCustomDomains = plan => {
  return true;
  // return !isProd || (plan && plan.name && plan.name.toLowerCase().includes('staff'));
};

const feedsVolume = false;

const teamsEnabled = !isProd;

const isTranslations = !isProd;

const multipartUpload = {
  chunkSizeMB: 20,
  chunksByIteration: 5 // upload chunks count at the same time
};

const canShowBackgrounds = (_: User | null) => true;

export const Features = {
  qrcodes,
  teamsEnabled,
  isTranslations,
  pauseRemoteConsumers,
  audioRecordingEnabled,
  hot_rejoin,
  feedsVolume,
  isTwoOptionsPopupEnabled,
  videoRecorderChooser,
  canUseCustomDomains,
  multipartUpload,
  canShowBackgrounds
};
