export const LAST_USED_BRAND = 'w-last-used-brand';
export const MY_ROOM_ID = 'my-room-id';
export const LIVE_STUDIO_SHOWN = 'live-studio-shown';
export const LAST_USED_STREAM_RESOLUTION = 'last-used-stream-resolution';
export const DEFAULT_MEDIA_KEY = 'w-default-media';
export const VIRTUAL_BG_GREEN_SCREEN = 'virtual_bg_green_screen';
export const BRAND_FILE_KEY = 'brand_file';
export const CHECK_DESTINATIONS_KEY = 'check_destinations_date';
export const DESTINATIONS_STORAGE_KEY = 'destinationPopup';
export const VIDEO_FROM_AI_KEY = 'video_from_ai';
export const BRAND_FILES_KEY = 'wv.brandFiles';
export const SYSTEM_FILES_KEY = 'wv.systemFiles';
export const VIRTUAL_BACKGROUNDS_KEY = 'virtual_backgrounds';
