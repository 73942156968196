import { useCallback, FocusEvent, FC, KeyboardEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';

import FormHelperText, { FormHelperTextVariants } from '../FormHelperText';
import Input, { InputVariants } from '../Input';
import InputLabel, { LabelVariant } from '../InputLabel';

import TextInputWrapper, { Optional } from './Wrapper';
import { useTextFieldFocusStatus } from './hooks';

export type TextInputOwnProps = {
  label: string;
  helperTextVariant: FormHelperTextVariants;
  type?: string;
  disabled?: boolean;
  showError?: boolean;
  autoFocus?: boolean;
  isTruncate?: boolean;
  isOptional?: boolean;
  placeholder?: string;
  inputVariant?: InputVariants;
  labelVariant?: LabelVariant;
  isFieldFocused?: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

export type TextInputProps = FieldRenderProps<string, HTMLInputElement> & TextInputOwnProps & { required?: boolean };

const TextInput: FC<TextInputProps> = ({
  type = 'text',
  meta: { touched, error },
  label,
  input,
  required = false,
  disabled = false,
  showError = true,
  isTruncate = false,
  autoFocus,
  isOptional,
  placeholder = ' ',
  inputVariant = 'animated_placeholder',
  labelVariant = 'animated-inside',
  isFieldFocused = false,
  helperTextVariant,
  onKeyDown
}) => {
  const [fieldRef, isFocused, setIsFocused, isPlaceholderShown] = useTextFieldFocusStatus<HTMLInputElement>(
    isFieldFocused
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      input.onFocus(event);
      setIsFocused(true);
    },
    [input]
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      input.onBlur(event);
    },
    [input]
  );

  return (
    <>
      <TextInputWrapper isDisabled={disabled}>
        {(touched || disabled) && error && showError && (
          <FormHelperText variant={helperTextVariant}>{error}</FormHelperText>
        )}
        {isOptional && <Optional>Optional</Optional>}

        <InputLabel
          variant={labelVariant}
          isRequired={required}
          {...{
            isFocused,
            isPlaceholderShown,
            isValue: input.value !== ''
          }}
        >
          {label}
        </InputLabel>
        <Input
          ref={fieldRef}
          isTruncate={isTruncate}
          variant={inputVariant}
          {...{ type, placeholder }}
          hasError={touched && error}
          {...input}
          {...{
            ...(disabled && { disabled }),
            ...(autoFocus && { autoFocus }),
            ...(onKeyDown && { onKeyDown })
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </TextInputWrapper>
    </>
  );
};

export default TextInput;
